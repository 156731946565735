import {useState} from "react";
import ReactDataTable from "../../component/ReactDataTable";
import {dept_delete_many, dept_get_list} from '../../api/dept'
import cellEditFactory from 'react-bootstrap-table2-editor'
import {get_orgId, get_orgname} from "../../common/auth_utils";

const base_link = `/dept`;

const DeptTable = (props) => {
    const [params, setParams] = useState({
        sort: {field: 'ordernum', order: 'asc'},
        refresh: {},
        search: '',
    });

    // const set_fieldorder = (field, order) => {

    //     setParams({...params, sort: {field: field, order: order}})
    // }

    const fetch_page_data = (params, datafunc) => {

        let new_params = {...params, 'orgId': get_orgId(), sortfield: 'ordernum'};
        dept_get_list(new_params, datafunc);
    };


    const delete_many = (ids, func) => {
        dept_delete_many(ids, func);
    }

    const columns = [
        {
            text: "序号",
            dataField: "ordernum",

            headerStyle: {width: "20%"},
        },
        {
            text: "部门名称",
            dataField: "deptname",
        },
        {
            text: "创建时间",
            dataField: "tsCreated",
        },

    ];

    const cellEdit = cellEditFactory({
        mode: 'click'
    });


    return (
        <>
            <h4>
                {get_orgname()}
            </h4>
            <ReactDataTable
                id='id'
                height={'100%'}
                style={{height: 'calc(75vh - 20px)'}}
                columns={columns}
                showpage={false}
                pagesize={100}
                fetch_page={fetch_page_data}
                delete_many={delete_many}
                showadd={true}
                showedit={true}
                showdelete={true}
                showrefresh={true}
                // onSelect={}
                defaultSort={{field: 'ordernum', order: 'asc'}}
                sort={params.sort}
                cellEdit={cellEdit}

                base_link={base_link}

            >
            </ReactDataTable>
        </>
    );
}
export default DeptTable
