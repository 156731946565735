import jwtDecode from "jwt-decode";

const AUTH_KEY = "AUTH";
const AUTH_TIME = "AUTH_TIME";

export const store_auth = (data: any) => {
    sessionStorage.setItem(AUTH_KEY, data);

    let LOGIN_TIME = String(new Date().getTime());//记录登录时的时间戳
    localStorage.setItem(AUTH_TIME, LOGIN_TIME);
}


export const remove_auth = () => {
    sessionStorage.removeItem(AUTH_KEY);
    sessionStorage.removeItem(AUTH_TIME);
}

export const get_auth = () => {
    let raw_auth = sessionStorage.getItem(AUTH_KEY);
    return raw_auth;
}

export const get_access_token = () => {
    const principle = get_auth();
    return principle;
}

// 获取解析后的token
export const get_decode_token = () => {
    const user_token = get_auth()
    const user_data = jwtDecode(user_token)
    return user_data
}

// 获取用户角色
export const get_role = () => {
    const user_role =(user_data)=>user_data.role
    const role = user_role(get_decode_token())
    return role;
}

// 获取公司ID
export const get_orgId = () => {
    const func_orgid =(user_data)=>user_data.orgId
    const orgid = func_orgid(get_decode_token())
    return orgid;
}

// 获取公司名称
export const get_orgname = () => {
    const func_orgname =(user_data)=>user_data.orgname
    const orgname = func_orgname(get_decode_token())
    return orgname;
}
