import * as React from 'react';
import {Container, Nav, Navbar} from "react-bootstrap";
import {logout} from "../store/actions/account.actions";
import {useDispatch} from "react-redux";
import {get_auth, remove_auth} from "../common/auth_utils";

/***
 * BS5中还未正式发布offcanvas菜单功能
 * @param props
 * @constructor
 * 该组件中应判断
 */
const Header = props => {


    const headerView=()=>{
        if (get_auth()){
            return(
            <Navbar bg="light" expand="lg" className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark" style={{paddingLeft: '5px'}}>
            <Container fluid style={{marginTop:'0px'}}>

                {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                <a className="navbar-brand mr-auto mr-lg-0" href="#">
                <img src={"img/logo.png"} alt='' width={'40px'}/> 企业计步系统</a>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="navbar-nav ml-auto">
                    <Nav.Link href="#/profile">个人信息</Nav.Link>
                    <Nav.Link href="#/changepwd">修改密码</Nav.Link>
                    <Nav.Link href="#/changepwd" className="nav-link"
                    onClick={
                        event => {
                            event.preventDefault();
                            remove_auth();
                            dispatch(logout());
                            window.location.href = '/';
                        }
                    }>退出登录</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>)
        }else{
            return ;
        }

    }

    const dispatch = useDispatch();

    return (
        <>
        {headerView()}
        </>
    );
}

export default Header;
