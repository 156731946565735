import React, {Suspense} from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.css"
import {Container} from "react-bootstrap";
import Footer from "./layout/Footer";
// import {HashRouter as Router, Switch} from "react-router-dom"
import {Router, Switch} from "react-router-dom"
import history from "./history";
import PrivateRoute from "./routers/PrivateRoute";
import Login from "./pages/profile/LoginPage";
import {AccountRoute} from "./routers/AccountRoute";
import ChangePasswordMyselfPage from "./pages/profile/ChangePasswordMyselfPage";
import ProfilePage from "./pages/profile/ProfilePage";
import Main from "./routers/Main";
import Header from "./layout/Header";

function App() {

    return (
        <>
            <div className="App bg-light">
                <Suspense fallback={<div>加载中。。。</div>}>
                    <Router history={history}>
                        <Header/>
                        {/*<Menu/>*/}
                        <Container fluid style={{textAlign: "left", overflowY: 'scroll', padding: '2rem'}}>
                            <Switch>
                                <PrivateRoute exact path="/profile">
                                    <ProfilePage/>
                                </PrivateRoute>
                                <PrivateRoute exact path="/changepwd">
                                    <ChangePasswordMyselfPage/>
                                </PrivateRoute>
                                <AccountRoute exact path="/login"><Login/></AccountRoute>
                                {/*二级路由*/}
                                <PrivateRoute path="/">
                                    <Main/>
                                </PrivateRoute>
                            </Switch>
                        </Container>
                    </Router>
                </Suspense>
            </div>
            <Footer/>
        </>
    );
}


export default App;
