import {useCallback, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import ReactDataTable from "../../component/ReactDataTable";
import {qrcode_delete_many, qrcode_get_list, qrcode_get_sign, randcode_get} from '../../api/qrcode'
import {get_orgId, get_orgname} from "../../common/auth_utils";

import QRCode from 'qrcode.react';

const base_link = `api/qrcode`;
const QrCode = (props) => {

    const [params, setParams] = useState({
        sort: { field: '', order: '' },
        refresh: {},
        search: ''
    });
    const [modalShow, setModalShow] = useState(false);
    const [modalSignShow,setModalSignShow] = useState(false)
    const [qrcode, setQrcode] = useState('')
    const [randcode,setRandcode] = useState('')





    // const set_fieldorder = (field, order) => {
    //     setParams({ ...params, sort: { field: field, order: order } })
    // }

    const fetch_page_data = (params, datafunc) => {
        let new_params = { ...params, orgId:get_orgId() };
        qrcode_get_list(new_params, datafunc);
    };

    const delete_many = (ids, func) => {
        qrcode_delete_many(ids, func);
    }

    // 获取签到二维码

    const qrcode_get = (datafunc) => {
        qrcode_get_sign(datafunc => {
            setQrcode(datafunc.data)
        })
    }
    qrcode_get(useCallback)


    function MyVerticallyCenteredModal(props) {

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        签到二维码
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id='qrcodediv'>
                        <img src={qrcode} id='qrCode' alt="" height='260px' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a id='down_link' onClick={DownloadPic}>
                        <Button>下载二维码</Button>
                    </a>

                    <Button onClick={props.onHide}>关闭</Button>
                </Modal.Footer>
            </Modal>
        );
    }



    function MySignModal(props) {

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        员工注册二维码
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>{get_orgname()}</span>
                    {/* &nbsp;&nbsp; */}
                    <QRCode
                        id="qrCode"
                        value={`https://jsct.jsnjwj.com/join?org=${get_orgId()}&rcode=${randcode}`}
                        size={200}
                        style={{margin:'30px'}}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <a id='down_link' onClick={DownQRCodeloadPic}>
                        <Button>下载二维码</Button>
                    </a>

                    <Button onClick={props.onHide}>关闭</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    // 下载QRCode二维码图片
    const DownQRCodeloadPic = () => {
        const canvasImg: any = document.getElementById('qrCode'); // 获取canvas类型的二维码
        const img = new Image();
        img.src = canvasImg.toDataURL('image/png');
        const downLink: any = document.getElementById('down_link');
        downLink.href = img.src;
        downLink.download = '二维码'; // 图片name
    }

    // 下载img二维码
    function DownloadPic() {
        var imgs = document.getElementById('qrcodediv').getElementsByTagName("img");
        var a = document.createElement('a');
        a.download = '二维码';
        a.href = imgs[0].src;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }



    const columns = [

        {
            text: "随机码",
            dataField: "randcode",
        },
        {
            text: "创建时间",
            dataField: "tsCreated",
        },
        {
            text: "失效时间",
            dataField: "endvaliddate",
        },
        {
            text: '员工注册二维码',
            dataField: "qrcodebutton",
            formatter: (val, row) => {
                return (

                <Button size={'sm'} onClick={() => {
                    setModalSignShow(true)

                    setRandcode(row.randcode)

                }}>查看二维码</Button>

                )
            }
        }
    ];


    return (
        <div style={{height:'calc(100vh - 140px)'}}>
            <h4>
                {get_orgname()}
            </h4>
            <ReactDataTable
                id='id'
                columns={columns}
                height={'100%'}
                style={{height: 'calc(72vh - 20px)'}}
                pagesize={30}
                fetch_page={fetch_page_data}
                delete_many={delete_many}
                refresh={params.refresh}
                // showadd = {true}
                // showedit = false,
                showdelete = {true}
                showrefresh = {true}
                // onSelect={}
                defaultSort={{ field: '', order: 'asc' }}
                sort={params.sort}

                base_link={base_link}
            >
                <Button size={"sm"} style={{marginRight:'5px'}} onClick={
                    ()=>{randcode_get(function(data){
                        if (data){
                            setParams({ ...params, refresh: !params.refresh });
                        }
                    })
                    }
                    // https://jsct.jsnjwj.com/qrcode/createrandcode

                } >创建随机码</Button>

                <Button size={"sm"} variant="primary" onClick={() => setModalShow(true)}>
                    签到二维码
                </Button>

                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    modal_title = '签到二维码'
                />

                <MySignModal
                    show = {modalSignShow}
                    onHide={() => setModalSignShow(false)}
                />

            </ReactDataTable>
        </div>
    );
}


export default QrCode;
