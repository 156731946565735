import React, {useState} from "react";
import ReactDataTable from "../../component/ReactDataTable";
import {wxuser_delete_many, wxuser_get_list} from "../../api/wxuser";
import {get_orgId, get_orgname} from "../../common/auth_utils";


const base_link = `/wxuser`;
const WxuserTable = (props) => {

    const [params, setParams] = useState({
        sort: {field: '', order: ''},
        refresh: {},
        search: ''
    });


    const set_fieldorder = (field, order) => {
        setParams({...params, sort: {field: field, order: order}})
    }

    const fetch_page_data = (params, datafunc) => {
        let new_params = {...params, orgId: get_orgId()};
        wxuser_get_list(new_params, datafunc);
    };

    const delete_many = (ids, func) => {
        wxuser_delete_many(ids, func);
    }

    const columns = [
        {
            text: "部门",
            dataField: "dept.deptname",
            sort: true,
            onSort: (field, order) => set_fieldorder(field, order),
        },
        {
            text: "工号",
            dataField: "employeeno",
            headerStyle: {width: "10%"},
        },
        {
            text: "姓名",
            dataField: "realname",
            headerStyle: {width: "10%"},
            sort: true,
            onSort: (field, order) => set_fieldorder(field, order),
            // filter:textFilter()
        },
        {
            text: "性别",
            dataField: "gender",
            headerStyle: {width: "7%"},
            sort: true,
            onSort: (field, order) => set_fieldorder(field, order),
            formatter: (val, row) => {
                return val === '1' ? <span>男</span> : <span>女</span>
            }
        },
        {
            text: "手机号",
            dataField: "tel",
            headerStyle: {width: "17%"},
        },
        {
            text: "昵称",
            dataField: "nickname"
        },
        {
            text: "头像",
            dataField: "avatarUrl",
            headerStyle: {width: "8%"},
            formatter: (val, row) => {
                return <img src={val} alt="" height='50px'/>
            }
        },
        {
            text: "注册日期",
            dataField: "tsCreated",
            sort: true,
            onSort: (field, order) => set_fieldorder(field, order),
        },

    ];

    return (
        <>
            <h4>
                {get_orgname()}
            </h4>
            <ReactDataTable
                id='id'
                height={'100%'}
                style={{height: 'calc(72vh - 20px)'}}
                columns={columns}
                pagesize={30}
                fetch_page={fetch_page_data}
                delete_many={delete_many}
                showedit={true}
                showdelete={true}
                showrefresh={true}

                // onSelect={}
                defaultSort={{field: '', order: 'asc'}}
                sort={params.sort}

                base_link={base_link}
            >

            </ReactDataTable>

        </>
    );
}


export default WxuserTable
