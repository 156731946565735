export const process_http_error = (response, callback, msg = "请求出错！") => {
    if (response) {
        if (response.status >= 500) {
            msg = "网络错误！";
            callback({'ok': false, msg: msg});
            return true;
        } else {
            if (!msg) msg = response.statusText;
            callback({'ok': false, msg: msg});
            return true;
        }
    }
    return false;
}
