import qs from "qs"
import {delete_data, get_data, post_data} from "../common/simple_request";

const base_url = `/api/dept`;


export function dept_get_list(params, callback = (data: any) => {
}) {
    
    return get_data(`${base_url}/bycondition`, params, callback);
}

export function dept_delete_many(objs: [], callback = (data: any) => {
}) {
    return delete_data(`${base_url}/delmany`, objs, callback);
}

export function dept_load_one(id?: string, callback = (data: any) => {
}) {
    return get_data(`${base_url}/load/${id}`, null, callback);
}

export function dept_save_one(obj: any, callback = (data: any) => {
}) {
    return post_data(`${base_url}/save`, qs.stringify(obj), callback);
}
