import * as React from "react";
import ProfilePage from "../pages/profile/ProfilePage";
import ChangePasswordMyselfPage from "../pages/profile/ChangePasswordMyselfPage";

const Index = React.lazy(() => import('../pages/home'));
const TestPage = React.lazy(() => import('../pages/test/TestPage'));

const routes = [
    {path: '/', exact: true, name: 'Home', component: Index},
    {path: '/index', exact: true, name: '首页', component: Index},
    {path: '/profile', exact: true, name: '个人信息', component: ProfilePage},
    {path: '/changepwd', exact: true, name: '修改密码', component: ChangePasswordMyselfPage},
    {path: '/test', exact: true, name: '测试页面', component: TestPage},
];


// const add_routes_to = (main_routes, new_routes) => {
//     new_routes.forEach(function (item: any, index) {
//         main_routes.push(item);
//     });
// }

// add_routes_to(routes, routes_agency);

// console.log(routes)
export default routes;
