import {combineReducers, createStore} from "redux";
import breadcrumbReducer from "./reducers/breadcrumb.reducer";
import accountReducer from "./reducers/account.reducer";


const rootReducers = combineReducers({
    breadcrumb: breadcrumbReducer,
    account: accountReducer,
});

const store = createStore(rootReducers);

export default store;
