import React, {useState} from "react";
import ReactDataTable from "../../component/ReactDataTable";
import {stats_get_list} from "../../api/stats";
import {get_orgId, get_orgname} from "../../common/auth_utils";


const DeptUserStats = (props) => {

    const [params, setParams] = useState({
        sort: {field: '', order: ''},
        refresh: {},
        search: ''
    });


    // const set_fieldorder = (field, order) => {
    //     setParams({...params, sort: {field: field, order: order}})
    // }

    const fetch_page_data = (params, datafunc) => {
        let new_params = {...params, orgId:get_orgId()};
        stats_get_list(new_params, datafunc);
    };
    const columns = [

        {
            text: "序号",
            dataField: "ordernum"
        },
        {
            text: "部门名称",
            dataField: "deptname"
        },
        {
            text: "部门人数",
            dataField: "usercount"
        },

    ];

    return (
        <>
            <h4>
                {get_orgname()}
            </h4>
            <ReactDataTable
                id='id'
                // height={'100%'}
                columns={columns}
                style={{height: 'calc(70vh - 20px)'}}
                pagesize={30}
                fetch_page={fetch_page_data}
                // showdelete = {true}

                // onSelect={}
                defaultSort={{field: '', order: 'asc'}}
                sort={params.sort}

            >

            </ReactDataTable>

        </>
    );
}



export default DeptUserStats

