import {Link,} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {set_current_path} from "../../store/actions/breadcrumb.actions";
import {useDispatch} from "react-redux"
import {Dispatch, useEffect, useState} from "react";
import {Alert, Button, Container, Form} from "react-bootstrap";
import {user_load_profile, user_save_profile} from "../../api/user";

const Profile = (props) => {

    const dispatch: Dispatch<any> = useDispatch();

    const [state, setState] = useState({
        ok: null,
        msg: '',
        data: {
            username: '',
            usertype: '',
            admindiv: {name: ''},
            enterprise: {name: ''},
            contact: '',
            gender:'',
            email: '',
            tel: '',
            remark: ''
        }
    });

    const handleChange = ({name, value}) => {
        setState({
            ok: state.ok,
            msg: state.msg,
            data: {...state.data, [name]: value}
        });
    };

    useEffect(() => {
        user_load_profile(data => {
            if (data) {
                setState({
                    ['data']: data,
                    ['ok']: null,
                    ['msg']: ''
                });
            } else {
                setState({
                        ['data']: null,
                        ['ok']: false,
                        ['msg']: '加载个人信息失败！'
                    }
                );
            }
        });
    }, []);

    const onSubmit = (data) => {
        const {
            contact, tel, email, remark
        } = data;

        user_save_profile(
            {
                contact, tel, email, remark
            },
            function (data) {
                setState({
                    ['data']: data,
                    ['ok']: data.ok,
                    ['msg']: data.msg
                });

                toast(data.msg);
                setTimeout(function () {
                    window.location.reload();
                },2000);

            });
    }


    dispatch(set_current_path({label: '个人信息', link: '/profile'}));

    const {ok, msg, data} = state;



    return (
        <Container style={{height: "calc(100vh - 84px)", textAlign: "left", width: "50%", margin: "60px auto"}}>
            {/*<Divider/>
            <Breadcrumb size={"big"}>
                <Breadcrumb.Section href={'#/'}>首页</Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron'/>
                <Breadcrumb.Section href={'#/profile'} active>个人信息</Breadcrumb.Section>
            </Breadcrumb>
            */}
            <Form onSubmit={(e) => {
                e.preventDefault();
                onSubmit(state.data);
            }}>
                <Form.Group>
                    <Form.Label>
                        用户名
                    </Form.Label>
                    <Form.Control
                        type={"text"}
                                readOnly

                                name={"username"}
                                value={data.username || ''}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>
                        联系人
                    </Form.Label>
                    <Form.Control type={"text"}
                                value={data.contact || ''}
                                name={"contact"}
                                onChange={e => handleChange(e.target)}
                    />

                    <Form.Label>
                        电话号码
                    </Form.Label>
                    <Form.Control type={"tel"}
                                value={data.tel || ''}
                                name={"tel"}
                                onChange={e => handleChange(e.target)}
                    />
                </Form.Group>

                <Form.Label>
                    备注
                </Form.Label>
                <Form.Control
                    type={"textarea"}
                    name="remark"
                    placeholder="请填写备注信息"
                    value={data.remark || ''}
                    onChange={e => handleChange(e.target)}/>

                {ok === false && <Alert variant={"info"}>{msg}</Alert>}

                {ok === true && <Alert variant={"danger"}>{msg}</Alert>}

                <Form.Group>
                    <Button variant={"primary"} type={"submit"} style={{marginTop: "10px", marginRight: "50px"}}>保存</Button>
                    <Link to={'/'}>
                    <Button type={"reset"} style={{marginTop: "10px"}}>返回</Button>
                    </Link>
                </Form.Group>
            </Form>
            <ToastContainer/>
        </Container>
    )
}

export default Profile;
