import React from "react";
import {Route, Switch} from 'react-router-dom'
import {get_role} from "../common/auth_utils";

import QRCode from '../pages/management/QrCode'
import DeptTable from '../pages/management/DeptTable'
import WxuserTable from "../pages/management/WxuserTable";
import WxuserFormPage from '../pages/management/WxuserFormPage'
import DeptUserStats from '../pages/statistics/DeptUserStats'
import DeptFormPage from '../pages/management/DeptFormPage'
import StepDetailStats from '../pages/statistics/StepDetailStats'
import QueryDownload from '../pages/management/QueryDownload'

const systemRoute = () => {
    return (
        <>
            <Route path='/qrcode' name='随机码' exact={true} component={QRCode}/>

            <Route path='/wxuser/edit/:id' name='微信用户信息修改' exact={true} component={WxuserFormPage}/>
            <Route path='/wxuser' name='微信用户信息' exact={true} component={WxuserTable}/>

            <Route path='/dept/add' name='部门添加' exact={true} component={DeptFormPage}/>
            <Route path='/dept/edit/:id' name='部门修改' exact={true} component={DeptFormPage}/>
            <Route path='/dept' name='部门管理' exact={true} component={DeptTable}/>

            <Route path='/deptuser' name='部门用户统计' exact={true} component={DeptUserStats}/>
            {/*<Route path='/deptsteps' name='部门步数统计' exact={true} component={DeptSteps}/>*/}
            {/*<Route path='/usersteps' name='用户步数' exact={true} component={UserSteps}/>*/}
            <Route path='/setpdetail' name='步数明细统计' exact={true} component={StepDetailStats}/>
            <Route path='/querydownload' name='数据查询' exact={true} component={QueryDownload}/>
        </>
    )
}

const ContentRoute = () => {
    return (
        <div>
            <Switch>
                {systemRoute()}
            </Switch>
        </div>
    )
}
export default ContentRoute;
