import React from "react";
import {toast, ToastContainer} from "react-toastify";
import WxuserForm from "./WxuserForm";
import {wxuser_save_one} from "../../api/wxuser";

const list_page = '/wxuser';

class WxuserFormPage extends React.PureComponent {
    id = '';

    state = {
        msg: '',
        ok: null
    };

    constructor(props) {
        super(props);
        this.id = props.match.params.id;
    }

    handleSubmit = (data) => {
        let thiz = this;


        const {id, realname, tel, gender, employeeno, deptId} = data;


        wxuser_save_one({
            id, employeeno, realname, tel, gender, deptId
        }, function (data) {
            if (data.msg) {
                toast(data.msg);
            }
            if (data.ok) {
                window.location.hash = list_page;
            } else {
                thiz.setState({ok: data.ok, msg: data.msg})
            }
        });
    };

    render() {
        const {ok, msg} = this.state;
        return (
            <>
                <h3 style={{marginTop:'60px' ,marginBottom: '50px',textAlign:'center'}}>
                    {this.id ? "编辑员工信息" : "创建员工信息"}
                </h3>
                <WxuserForm
                    id={this.id}
                    ok={ok}
                    msg={msg}

                    list_page={list_page}
                    handleSubmit={this.handleSubmit}
                />


                <ToastContainer/>

            </>
        )
    }
}

export default WxuserFormPage;
