import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import ReactDataTable from "../../component/ReactDataTable";
import {step_delete_many, step_get_list} from '../../api/step'
import {get_orgId, get_orgname} from "../../common/auth_utils";
import {dept_get_list} from "../../api/dept";
import {format_date, to_short_date, user_format_date} from "../../common/date_utils";
import DatePicker, {registerLocale} from 'react-datepicker'
import zh from 'date-fns/locale/zh-CN'

registerLocale('zh', zh)

const base_link = `/api/step`;
const StepDetailStats = (props) => {

    const [params, setParams] = useState({
        sort: {field: '', order: ''},
        refresh: {},
        search: ''
    });
    const [state, setState] = useState({
        data: {
            deptId: '',
            user: '',
            tel: '',

        }
    });
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date())
    const begymd = user_format_date(startDate)
    const endymd = user_format_date(endDate)


    const [dept, setDept] = useState([])

    function handleChange({name, value}) {
        const data = {...state.data};
        data[name] = value;
        setState({...state, 'data': data});
    }



    const fetch_page_data = (params, datafunc) => {
        let new_params = {
            ...params,
            orgId: get_orgId(),
            firstymd: begymd,
            secondymd: endymd,
            tel: state.data.tel,
            user: state.data.user,
            deptId: state.data.deptId
        };
        step_get_list(new_params, datafunc);
    };

    const delete_many = (ids, func) => {
        step_delete_many(ids, func);
    }

    // 获取部门
    const orgId = get_orgId()
    const deptList = (orgId) => {

        dept_get_list({'orgId': orgId}, function (data) {
            if (data.content) {
                setDept(data.content)
            }
        })
    }

    useEffect(() => {
        deptList(orgId)
    }, [orgId]);

    const deptmap = () => {

        return dept.map(item => {
            return <option value={item.id} key={item.id}>{item.deptname}</option>
        })
    }


    const columns = [
        {
            text: "部门",
            dataField: "user.dept.deptname",
            headerStyle: {width: "10%"},
        },
        {
            text: "姓名",
            dataField: "user.realname",
            headerStyle: {width: "10%"},
            // filter:textFilter()
        },
        {
            text: "手机",
            dataField: "user.tel"
        },
        {
            text: "昵称",
            dataField: "user.nickname",
            headerStyle: {width: "13%"},
        },
        {
            text: "头像",
            dataField: "user.avatarUrl",
            headerStyle: {width: "10%"},
            formatter: (val, row) => {
                return < img src={val} alt="" height='50px'/>
            }
        },
        {
            text: "运动日期",
            dataField: "ymd",
            formatter: (val, row) => {
                const dt = to_short_date(val+"","YYYYMMDD")
                const str_date = format_date(dt,"YYYY年MM月DD日");
                // let strDate = String(val);
                // let date = strDate.slice(0, 4) + '年' + strDate.slice(4, 6) + '月' + strDate.slice(6, 8) + '日'
                // return <span>{date}</span>
                return str_date;
            }
        },
        {
            text: "步数",
            dataField: "stepcount",
            formatter: (val, row) => {
                var format = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                return <span style={{float: 'right'}}>{format}</span>
            }
        },


    ];

    return (
        <>
            <h4>
                {get_orgname()}
            </h4>
            <ReactDataTable
                id='id'
                // height={'100%'}
                style={{height: 'calc(65vh - 20px)'}}
                columns={columns}
                pagesize={100}
                fetch_page={fetch_page_data}
                delete_many={delete_many}
                refresh={params.refresh}
                // showdelete = {true}
                // showrefresh = {true}

                // onSelect={}
                defaultSort={{field: '', order: 'asc'}}
                sort={params.sort}

                base_link={base_link}
            >
                <Row className="g-2">
                    <Col md>


                        <Form.Select aria-label="Default select example"
                                     name='deptId'
                                     onChange={e => {
                                         //@ts-ignore
                                         const {name, value} = e.target;
                                         handleChange({name, value})
                                     }}
                        >
                            <option value="">选择部门</option>
                            {deptmap()}
                        </Form.Select>
                    </Col>
                    <Col md>
                        <Form.Control size="sm" type="text"
                                      placeholder="用户名或昵称"
                                      name='user'
                                      onChange={e => handleChange(e.target)}
                        />
                    </Col>
                    <Col md>
                        <Form.Control size="sm" type="text"
                                      placeholder="手机号码"
                                      name='tel'
                                      onChange={e => handleChange(e.target)}
                        />
                    </Col>
                    {/* <Col md>
                    <Form.Control size="sm" type="text"
                    placeholder=""
                    name=''
                    onChange={e => handleChange(e.target)}/>
                </Col> */}
                    <Col md></Col>


                </Row>
                <Row>
                    <Col md></Col>
                    <Col md>
                        <span>
                            开始日期<DatePicker
                            as={DatePicker}
                            locale={'zh'}
                            dateFormat='yyyy-MM-dd'
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}/>
                        </span>
                    </Col>
                    <Col md>
                        <span>
                            结束日期<DatePicker
                            as={DatePicker}
                            locale={'zh'}
                            dateFormat='yyyy-MM-dd'
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}/>
                        </span>
                    </Col>
                    <Col md>
                        <Button style={{marginTop: '23px'}} size='sm' variant="primary" onClick={() => {
                            // handleSubmit(state)
                            setParams({...params, refresh: !params.refresh});
                        }}>查询</Button>
                    </Col>
                </Row>


            </ReactDataTable>

        </>
    );
}


export default StepDetailStats
