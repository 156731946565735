import request from "../common/request_utils";
import {process_http_error} from "./http_errors";


export function token_post(url, params, callback = (data: any) => {
}) {
    return request({
        url: url,
        method: 'post',
        data: params,
    }).then((params) => {
        params['ok'] = true;
        callback(params);
    }).catch(res => {
        process_http_error(res.response, callback, "用户名或密码错误！")
    });
};

export function get_data(url, params, callback = (data: any) => {
}) {
    return request({
        url: url,
        method: 'get',
        params: params,
    }).then((data) => {
        callback(data);
    }).catch(res => {
        process_http_error(res.response, callback);
    });
};

export function post_data(url, params, callback = (data: any) => {
}) {
    return request({
        url: url,
        method: 'post',
        data: params,
    }).then(data => {
        callback(data);
    }).catch(res => {
        process_http_error(res.response, callback);
    });
};


export function post_json(url, params, callback = (data: any) => {
}) {
    //@ts-ignore
    return request({
        url: url,
        method: 'post',
        data: params,
        header: {
            'Content-Type': 'application/json'
        }
    }).then(data => {
        callback(data);
    }).catch(res => {
        process_http_error(res.response, callback);
    });
};

export function delete_data(url, params, callback = (data: any) => {
}) {
    return request({
        url: url,
        method: 'delete',
        data: params,
    }).then((data) => {
        callback(data);
    }).catch(res => {
        process_http_error(res.response, callback);
    });
};


export function upload_file(url, params, callback = (data: any) => {
}) {
    return request({
        url: url,
        method: 'post',
        timeout: 30000, //解决导入数据过大时请求超时
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    }).then((data) => {
        callback(data);
    }).catch(res => {
        process_http_error(res.response, callback);
    });
};
