import './Footer.css';
import * as React from 'react';

const Footer = props => {
    const year = new Date().getFullYear();
    return (
        <div className="main-footer bg-dark text-light">
            <a target="_blank" href="#"><span>蓝之梦数据科技江苏有限公司</span>&copy; {year} </a>
        </div>
    );
};

export default Footer;
