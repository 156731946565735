import React from "react";
import {toast, ToastContainer} from "react-toastify";
import DeptForm from "./DeptForm";
import {dept_save_one} from "../../api/dept";
import {get_orgId} from "../../common/auth_utils";

const list_page = '/dept';

class DeptFormPage extends React.PureComponent {
    id = '';

    state = {
        msg: '',
        ok: null
    };

    constructor(props) {
        super(props);
        this.id = props.match.params.id;
    }

    handleSubmit = (data) => {
        let thiz = this;
        const orgId = get_orgId()
        const {id, deptname,ordernum} = data;
        dept_save_one({
            id, deptname,ordernum,orgId
        }, function (data) {
            if (data.msg) {
                toast(data.msg);
            }
            if (data.ok) {
                window.location.hash = list_page;
            } else {
                thiz.setState({ok: data.ok, msg: data.msg})
            }
        });
    };

    render() {
        const {ok, msg} = this.state;
        return (
            <>
                <h3 style={{marginTop:'100px',marginBottom:'30px',textAlign:'center'}}>
                    {this.id ? "编辑部门" : "创建部门"}
                </h3>
                <DeptForm
                    id={this.id}
                    ok={ok}
                    msg={msg}

                    list_page={list_page}
                    handleSubmit={this.handleSubmit}
                />
                <ToastContainer/>
            </>
        )
    }
}

export default DeptFormPage;
