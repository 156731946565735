import {Alert, Button, Form} from 'react-bootstrap'
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css"
import accountReducer, {initialState} from "../../store/reducers/account.reducer";
import {get_auth, store_auth} from "../../common/auth_utils";
import * as React from "react";
import {get_data} from "../../common/simple_request";
import {login} from "../../store/actions/account.actions";
import {do_login} from "../../api/login";

const Login = (props) => {

    const [_, dispatch] = React.useReducer(accountReducer, initialState);

    const [credentials, setCredentials] = React.useState({
        ok: null,
        username: "",
        password: "",
        error: "",
        data: null
    })

    //function to handle onChange event
    const handleChange = (event) => {
        const { name, value } = event.target;
        setCredentials({ ...credentials, [name]: value })
    }

    // function to handle form onSubmit event
    const handleSubmit = (event) => {
        event.preventDefault();
        //临时处理
        ///const full_username = `${CLIENT_ID}/${credentials.username}`;
        do_login(credentials.username, credentials.password, function (res) {
            setCredentials({ ...credentials, error: "登录出错：用户名或密码错误！" });
            //加载会计身份信息（临时，应在首页初始化时加载）
            get_data("/api/profile", null, function (data) {
            });

            if (res && res.ok) {
                setCredentials({ ...credentials, ok: true });
                store_auth(res.data);

                dispatch(login(get_auth()));
                // history.push("/");
                // 重新跳转
                window.location.href = "/";
            } else {

                // event.preventDefault();
                setCredentials({ ...credentials, error: "登录出错：用户名或密码错误！" });
                toast("登录出错：用户名或密码错误！");
            }
        });
    }


    return (
        <div>
            <div className="login">
                <h3>
                    请登录
                </h3>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label style={{ textAlign: "left" }}>用户名：</Form.Label>
                        <Form.Control
                            type={"text"}
                            placeholder='请输入用户名'
                            name="username"
                            value={credentials.username}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label style={{ textAlign: "left" }}>密码：</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder='请输入密码'
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        {
                            credentials.ok === true ?
                                <Alert variant={"success"}>登录成功！</Alert> : null
                        }
                        {
                            credentials.ok === false ?
                                <Alert variant={"danger"}>{"登录失败" + credentials.error} </Alert> : null
                        }
                    </Form.Group>
                    <Button
                        variant={"primary"}
                        type='submit'
                        style={{ marginTop: "10px" }}
                    >
                        确定
                    </Button>
                </Form>
                <ToastContainer />
            </div>
            <div className="bgimg" style={{background: `url("../img/login.jpeg") no-repeat`, backgroundSize: "cover"}}>
            </div>
        </div>
    )
}

export default Login;
