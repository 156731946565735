import {delete_data, get_data} from "../common/simple_request";

const base_url = `/api/step`;


export function step_get_list(params, callback = (data: any) => {
}) {
    return get_data(`${base_url}/bycondition`, params, callback);
}
export function step_delete_many(objs: [], callback = (data: any) => {
}) {

    return delete_data(`${base_url}/delmany`, objs, callback);
}

