import {Button, Col, Form, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import {dept_load_one} from "../../api/dept";

const DeptForm = (props) => {

    const { id, list_page, handleSubmit } = props;

    const [state, setState] = useState({
        ok: null,
        msg: '',
        data: {
            id: '',
            deptname: '',
            ordernum: '',
            orgId: ''
        }
    });

    function handleChange({ name, value }) {
        const data = { ...state.data };
        data[name] = value;
        setState({ ...state, 'data': data });
    }

    useEffect(() => {

        if (!id)
            return;
        dept_load_one(id, function (data) {
            const orgId = data.org.id
            const { id, deptname, ordernum } = data;

            setState({
                ...state, data: {
                    id,
                    deptname,
                    ordernum,
                    orgId: orgId
                }
            });
        })
    }, [id]);


    return (
        <>
            <Form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(state.data);
            }}>
                <Row>
                    <Col md={3}></Col>
                    <Form.Group className='mb-3' as={Col} md={6}>
                        <Form.Label>
                            部门名称
                        </Form.Label>
                        <Form.Control
                            as={"input"}
                            name={"deptname"}
                            value={state.data.deptname}
                            onChange={e => handleChange(e.target)} />
                    </Form.Group>
                </Row>
                <Row>
                    <Col md={3}></Col>

                    <Form.Group className='mb-3' as={Col} md={6}>
                        <Form.Label>
                            序号
                        </Form.Label>
                        <Form.Control
                            as={"input"}
                            name={"ordernum"}
                            value={state.data.ordernum}
                            onChange={e => handleChange(e.target)} />
                    </Form.Group>
                </Row>

                {/* <br /> */}

                <Row>
                    <Col md={3}></Col>
                    <Form.Group style={{padding: "15px"}}>
                        <Button variant={"primary"} type="submit" style={{ marginTop: "10px", marginRight: "50px" }}>确定</Button>
                        <Link to={list_page}>
                            <Button variant={"secondary"} style={{ marginTop: "10px" }}>返回</Button>
                        </Link>
                    </Form.Group>
                    {/* <ButtonGroup as={Col} md={3}>
                        <Button size={"sm"} type={"submit"} variant={"primary"} style={{marginRight: "50px"}}>保存</Button>
                        <Button size={"sm"} variant={"secondary"}><Link to={list_page}>返回</Link></Button>
                    </ButtonGroup>
                    <Button variant={"primary"} type="submit" style={{ marginTop: "10px", marginRight: "50px" }}>确定</Button>
                    <Link to={list_page}>
                        <Button variant={"secondary"} style={{ marginTop: "10px" }}>返回</Button>
                    </Link> */}
                </Row>
            </Form>
        </>
    )
}

export default DeptForm;
