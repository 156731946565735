// import {createHashHistory} from 'history';
//
// // createBrowserHistory
// // 采用hash局部路由，即所有访问路径均以#开头
// export default createHashHistory();

import {createHashHistory as createHistory} from 'history'

const history = createHistory()
// @ts-ignore
// history.pushLater = (...args) => setImmediate(() => history.push(...args))
export default history
