import React from 'react';
import {Col, Nav, Navbar, Row} from 'react-bootstrap'

import './home.css'
import ContentRoute from '../routers/ContentRoute'
import {get_role} from '../common/auth_utils';

const LeftView = (pagelist) => {
    const role = get_role()
    var PowerPages = []
    for (var i = 0; i < pagelist.length; i++) {
        PowerPages.push(pagelist[i])
    }
    return PowerPages.map(item => {
            if (!item.name) {
                return '';
            }
            return (
                <Nav.Item style={{textAlign: "left"}} key={item.name}>
                    <Nav.Link href={item.link}>{item.name}</Nav.Link>
                </Nav.Item>

            )
        }
    )
}

function Home() {

    const pages = [
        // {name: '用户管理', link: '#/wxuser', power: ['SYSTEM']},
        {name: '部门管理', link: '#/dept', power: ['SYSTEM']},
        {name: '微信用户管理', link: '#/wxuser', power: ['SYSTEM']},
        {name: '随机码', link: '#/qrcode', power: ['SYSTEM']},


        {name: '部门用户统计', link: '#/deptuser', power: ['SYSTEM']},
        // {name: '部门步数统计', link: '#/deptsteps', power: ['SYSTEM']},
        // {name: '步数汇总统计', link: '#/usersteps', power: ['SYSTEM']},
        {name: '步数明细统计', link: '#/setpdetail', power: ['SYSTEM']},
        {name: '达标数据查询', link: '#/querydownload', power: ['SYSTEM']},
    ]

    return (
        <Row>
            <Col lg={2} md={2} style={{textAlign: "left"}}>
                <Navbar bg="light" style={{height: 'calc(80vh  - 100px)'}}>
                    <Nav className="flex-column" style={{verticalAlign: "top", alignItems: "flex-start"}}>
                        {LeftView(pages)}
                    </Nav>
                </Navbar>
            </Col>
            <Col lg={10} md={10} className='content' style={{height: 'calc(100vh  - 100px)',}}>
                <ContentRoute/>
            </Col>
        </Row>
    )
}

export default Home;
