import * as React from "react"

//import Route and Redirect
import {Route} from "react-router-dom"
import {get_auth} from "../common/auth_utils";
import Login from "../pages/profile/LoginPage";
import {RouteProps} from "react-router"

//Private route for authentication
/*
const PrivateRoute = ({component: Component, ...rest}) => {
    const token = get_auth();

    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /login page
        <Route {...rest} render={props => (
            token ?
                <Component {...props} />
                : <Redirect to="/"/>
        )}/>
    );
};
*/

const PrivateRoute = ({children, ...rest}: RouteProps) => {

    //使用useSelector？动态更新？
    const token = get_auth();

    // console.log("用户身份=", token, children, rest)
    return (
        <Route
            {...rest}
            render={() =>
                token ? (
                    children
                ) : <Login/>
            }
        />
    );
}


export default PrivateRoute;
