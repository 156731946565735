import {Redirect, Route, RouteProps} from "react-router-dom";
import * as React from "react";
import {useSelector} from "react-redux";
import Login from "../pages/profile/LoginPage";


export function AccountRoute({ children, ...rest }: RouteProps): JSX.Element {

    const account = useSelector((state:any) => state.account);

    return (
        <Route
            {...rest}
            render={() =>
                account.token ? (
                    <Redirect
                        to={{
                            pathname: "/index"
                        }}
                    />
                ) : <Login/>
            }
        />
    );
}
