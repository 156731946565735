import qs from "qs"
import {get_data, post_data} from "../common/simple_request";

const base_url = `/api`;

//管理员修改用户密码
export function change_password_admin(obj: any, callback = data => {
}) {
    return post_data(`${base_url}/user/adminchangepwd`, qs.stringify(obj), callback);
}

//用户自行修改密码
export function change_password_myself(obj: any, callback = data => {
}) {
    return post_data(`${base_url}/user/myselfchangepwd`, qs.stringify(obj), callback);
}

//加载用户
export function user_load_profile(callback = data => {
}) {
    return get_data(`${base_url}/profile`, null, callback);
}

//用户头像采用独立的请求上传
export function user_save_profile(params, callback = data => {
}) {
    return post_data(`${base_url}/profile`, qs.stringify(params), callback);
}


export function user_load_one(id?: string, callback = (data: any) => {
}) {
    return get_data(`${base_url}/user/load/${id}`, null, callback);
}

export function user_save_one(obj: any, callback = (data: any) => {
}) {
    return post_data(`${base_url}/user/save`, qs.stringify(obj), callback);
}
