import React, {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {get_orgId} from "../../common/auth_utils";


import "react-datepicker/dist/react-datepicker.css";
import {wxuser_load_one} from "../../api/wxuser";
import {dept_get_list} from "../../api/dept";

const WxuserForm = (props) => {

    const {id, list_page, handleSubmit} = props;
    const [depts, setDepts] = useState([])

    const [state, setState] = useState({
        ok: null,
        msg: '',
        data: {
            id: '',
            deptname: '',
            employeeno: '',
            ordernum: '',
            realname: '',
            tel: '',
            gender: '',
            deptId: ''
        }
    });

    function handleChange({name, value}) {
        const data = {...state.data};
        data[name] = value;
        setState({...state, 'data': data});
    }

    const deptList = (orgId) => {
        dept_get_list({'orgId': orgId}, function (data) {
                if (data.content) {
                    setDepts(data.content)
                }
            }
        )
    }

    useEffect(() => {

        if (!id)
            return;
        wxuser_load_one(id, function (data) {
            const {id, realname, tel, gender, employeeno} = data;
            const {deptname, ordernum,} = data.dept
            const deptId = data.dept.id


            setState({
                ...state, data: {
                    id,
                    deptname,
                    employeeno,
                    ordernum,
                    realname,
                    tel,
                    gender,
                    deptId
                }
            });
        });

    }, [id]);
    var orgId = get_orgId()
    useEffect(()=>{
            deptList(orgId)
    },[orgId]);


    const radio_gender = (gender) => {
        let male_checked = {};
        let female_checked = {};
        if (gender === '1' || gender === 1) {
            male_checked['checked'] = true;
            delete female_checked['checked'];
        } else {
            female_checked['checked'] = true;
            delete male_checked['checked'];
        }

        return (

            <>
                <Form.Check
                    className="mb-3"
                    inline
                    label="男"
                    name="gender"
                    type='radio'
                    id='male'
                    {...male_checked}
                    value='1'
                    onChange={e => {
                    }}
                    onClick={e => {
                        //@ts-ignore
                        const {name, value} = e.target;
                        handleChange({name, value})
                        // handleClick(e)
                    }}
                />
                <Form.Check
                    inline
                    label="女"
                    name="gender"
                    type='radio'
                    id='female'
                    value='2'
                    {...female_checked}
                    onChange={e => {
                    }}
                    onClick={e => {
                        //@ts-ignore
                        const {name, value} = e.target;
                        handleChange({name, value})
                        // handleClick(e)
                    }
                    }
                />
            </>
        )

    }


    const mapDept = (deptList) => {
        return deptList.map(item => {
            return <option value={item.id} key={item.id}>{item.deptname}</option>
        })
    }



    return (
        <Form style={{width: '100%',paddingLeft:'60px'}} onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(state.data);
        }}>
            <Form.Group as={Row}>

                <Form.Label column sm='2' className="mb-3">
                    所在部门
                </Form.Label>

                <Col sm='8'>
                    <Form.Select
                        style={{width: '300px'}}
                        aria-label="Default select example"
                        name="deptId"
                        value={state.data.deptId}
                        onChange={e => {
                            //@ts-ignore
                            const {name, value} = e.target;

                            handleChange({name, value})
                        }}
                    >
                        {/* <option value={state.data.deptid}>{state.data.deptname}</option> */}
                        {mapDept(depts)}

                    </Form.Select>
                </Col>

            </Form.Group>


            <Form.Group as={Row}>

                <Form.Label column sm='2' className="mb-3">
                    员工工号
                </Form.Label>
                <Col sm='6'>
                    <Form.Control
                        as={"input"}
                        name={"employeeno"}
                        value={state.data.employeeno || ''}
                        placeholder="非必填"
                        onChange={e => handleChange(e.target)}/>
                </Col>
            </Form.Group>


            <Form.Group as={Row}>
                <Form.Label column sm='2' className="mb-3">
                    员工姓名
                </Form.Label>
                <Col sm='6'>
                    <Form.Control
                        as={"input"}
                        name={"realname"}
                        value={state.data.realname}
                        onChange={e => handleChange(e.target)}/>
                </Col>
            </Form.Group>


            <Form.Group as={Row}>
                <Form.Label column sm='2' className="mb-3">
                    性别
                </Form.Label>
                <Col sm='6'>
                    {radio_gender(state.data.gender)}
                </Col>

            </Form.Group>


            <Form.Group as={Row}>
                <Form.Label column sm='2' className="mb-5">
                    手机
                </Form.Label>
                <Col sm='6'>
                    <Form.Control
                        as={"input"}
                        name={"tel"}
                        value={state.data.tel}
                        onChange={e => handleChange(e.target)}/>
                </Col>
            </Form.Group>

            {/* <Form.Group as={Row}> */}

            {/* <ButtonGroup as={Col} sm='5'>

                <Button size={"sm"} type={"submit"} variant={"primary"} style={{marginRight: "50px"}}>保存</Button>

                <Button size={"sm"} variant={"secondary"}><Link to={list_page}>返回</Link></Button>

            </ButtonGroup> */}
            <Form.Group >
                <Button variant={"primary"} type="submit" style={{marginTop: "10px", marginRight: "50px"}}>确定</Button>
                <Link to={list_page}>
                    <Button variant={"secondary"} style={{marginTop: "10px"}}>返回</Button>
                </Link>
            </Form.Group>

            {/* </Form.Group> */}

        </Form>


    )
}

export default WxuserForm;
