import {SET_CURRENT_PATH} from "../actions/breadcrumb.actions";

export const initialState = {
    current: {label: '', link: ''}
};

export default function breadcrumbReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_PATH:
            return {...state, current: action.current};
        default:
            return {...state};
    }
}
