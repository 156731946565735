import {Button} from "react-bootstrap";
// import '../../node_modules/font-awesome/css/font-awesome.min.css';
// import 'react-fontawesome';
import 'font-awesome/css/font-awesome.min.css';

const TableToolBar = (props) => {
    const {
        onCreate,
        onEdit,
        onDelete,
        onRefresh,
    } = props;

    return <>
        {onCreate && <Button variant="primary" size={"sm"} onClick={() => onCreate(false)}>
            <i className="fas fa fa-plus"></i>
        </Button>}&nbsp;
        {onEdit && <Button variant="info" size={"sm"} onClick={() => onEdit(true)}>
            <i className="fas fa fa-edit"></i>
        </Button>}&nbsp;
        {onDelete && <Button variant="danger" size={"sm"} onClick={() => onDelete()}>
            <i className="fas fa fa-trash"></i>
        </Button>}&nbsp;
        {onRefresh && <Button variant="success" size={"sm"} onClick={() => onRefresh('refresh')}>
            <i className="fas fa fa-refresh"></i>
        </Button>}&nbsp;
    </>
}


export default TableToolBar;
