export const LOG_IN: string = "LOG_IN";
export const LOG_OUT: string = "LOG_OUT";

export function login(token: {}) {
    return {type: LOG_IN, token: token};
}

export function logout() {
    return {type: LOG_OUT};
}
