import React, {useState} from 'react'
import {Alert, Button, Form} from 'react-bootstrap';
import {Link} from "react-router-dom";

const ChangePasswordForm = (props) => {

    const {id, link_return, verifyoldpassword, ok, msg, handleSubmit} = props;

    const [data, setData] = useState({
        id: id,
        oldpassword: '',
        password: '',
        newpassword: '',
    });

    const handleChange = ({name, value}) => {
        setData({...data, [name]: value});
    };

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(data);
        }}>
            {
                verifyoldpassword
                &&
                <Form.Group style={{width: "30%", textAlign: "left", margin: "50px auto"}}>
                    <Form.Label>
                        原密码
                    </Form.Label>
                    <Form.Control
                        type="password"
                        name="oldpassword"
                        placeholder="请输入原密码"
                        value={data.oldpassword}
                        onChange={e => handleChange(e.target)}
                        required
                    />
                </Form.Group>

            }

            <Form.Group style={{width: "30%", textAlign: "left", margin: "0 auto"}}>
                <Form.Label>新密码</Form.Label>
                <Form.Control
                    type="password"
                    name="password"
                    placeholder="请输入新密码"
                    value={data.password}
                    onChange={e => handleChange(e.target)}
                    required
                />
            </Form.Group>

            <Form.Group style={{width: "30%", textAlign: "left", margin: "0 auto"}}>
                <Form.Label>确认新密码</Form.Label>
                <Form.Control
                    type="password"
                    name="newpassword"
                    placeholder="请再次输入新密码"
                    value={data.newpassword}
                    onChange={e => handleChange(e.target)}
                    required
                />
            </Form.Group>

            {
                data.password !== data.newpassword &&
                (
                    <Alert variant={"danger"}>
                        "两次密码输入不一致！"
                    </Alert>
                )
            }

            {
                ok === false &&
                <Alert variant={"danger"}>{msg}</Alert>
            }

            {
                ok === true &&
                <Alert variant={"success"}>{msg}</Alert>
            }
            <Form.Group style={{width: "30%", textAlign: "left", margin: "0 auto"}}>
                <Button variant={"primary"} type="submit" style={{marginTop: "10px", marginRight: "50px"}}>确定</Button>
                <Link to={link_return}>
                    <Button variant={"secondary"} style={{marginTop: "10px"}}>返回</Button>
                </Link>
            </Form.Group>
        </Form>
    )
}

export default ChangePasswordForm;
