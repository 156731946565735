import React, {Dispatch, useState} from "react";
import ChangePasswordForm from "./ChangePasswordForm";
import {change_password_myself} from "../../api/user";
import {useDispatch} from "react-redux"
import {toast, ToastContainer} from "react-toastify";
import {set_current_path} from "../../store/actions/breadcrumb.actions";
import {remove_auth} from "../../common/auth_utils";
import {logout} from "../../store/actions/account.actions";

const ChangePasswordMyselfPage = (props) => {

    const dispatch: Dispatch<any> = useDispatch();

    const id = props.match && props.match.params ? props.match.params.id : '';

    const [state, setState] = useState({
        msg: '',
        ok: null
    });

    const handleSubmit = (data) => {
        let s_data = {...data};
        change_password_myself(s_data, function (data) {
            if (data.ok) {
                remove_auth();
                // window.location.hash = '/';
                // @ts-ignore
                dispatch(logout());
                window.location.hash = '/';

            } else {
                toast(data.msg);
                setState({ok: data.ok, msg: data.msg});
            }
        });
    };

    dispatch(set_current_path({label: '修改密码', link: '/changepwd'}));

    const {ok, msg} = state;
    return (
        <>
            <ChangePasswordForm
                verifyoldpassword={true}
                id={id}
                ok={ok}
                msg={msg}
                style={{height: 'calc(100vh - 88px)'}}
                handleSubmit={handleSubmit}
                link_return={"/"}
            />
            <ToastContainer/>
        </>
    )
}

// export default connect(null, {logout})(ChangePasswordMyselfPage);
export default ChangePasswordMyselfPage;
