import {LOG_IN, LOG_OUT} from "../actions/account.actions";

export const initialState = {
    token: ""
};
//可以类型来限制传入和返回的变量
function accountReducer(state = initialState, action) {
    switch (action.type) {
        case LOG_IN: {
            return {...state, token: action.token};
        }
        case LOG_OUT: {
            return {...state, token: null};
        }
        default:
            return {...state};
    }
}


export default accountReducer;
