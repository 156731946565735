import {Route, Switch} from "react-router-dom";
import routes from "./routes";
import Home from "../pages/home";

const switch_routes = routes.map((route, idx) => {
    return route.component && (
        <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={props => (
                // <PrivateRoute exact path={route.path} component={route.component}/>
                <route.component {...props} />
            )}/>
    )
});

//Main comp
const Main = (props) => {

    // console.log("路由=", switch_routes)

    return (<Switch>
            {/*先展开变量，然后放在这里，防止短路*/}
            {switch_routes}
            <Route><Home/></Route>
            {/*<Route component={ErrorPage}/>*/}
        </Switch>
    );
}


//export main comp
export default Main
