import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {PaginationListStandalone, PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Card, Col, Row} from "react-bootstrap";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import TableToolBar from "./TableToolBar";
import "./ReactDataTable.css"

const ReactDataTable = (props) => {
    //属性
    const {
        showadd = false,
        showedit = false,
        showdelete = false,
        showrefresh = false,
        id = 'id',
        // title,
        // displayfield,
        height,
        style,
        showpage = true,
        pagesize = 20,
        columns,
        defaultSort = {field: '', order: ''},
        sort = {field: '', order: ''},
        children,
        fetch_page,
        // onSelect,
        selected,
        selection_mode = 'checkbox',
        base_link = '',
        save_one = null,
        delete_many = (ids, func) => {
        },
        callbackrows = (rows) => {
        },
        refresh,
        handleOnSelectAllOuter = null,
        handleOnSelectOuter = null
    } = props;

    //状态监控
    const [data, setData] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(pagesize);
    const [page, setPage] = useState(1);

    const [selectedIds, setSelectedIds] = useState([]);

    const [refresh_params, setRefreshParams] = useState({});
    /****
     * 下方20行为选择事件
     */
    // const [selectedRows, setSelectedRows] = React.useState([]);
    // const [toggleCleared, setToggleCleared] = React.useState(false);

    //查询参数
    const build_params = () => {
        const params = {
            pagenum: page,
            pagesize: perPage,
            sortfield: sort.field ? sort.field : defaultSort.field,
            sortorder: sort.order ? sort.order || 'asc' : defaultSort.order || 'asc'
        };
        return params;
    };

    //数据请求
    const request_datas = page => {
        // setLoading(true);
        fetch_page(build_params(), data => {
            if (Array.isArray(data)) {
                setData(data);
                callbackrows(data);
            }
            if (data.content) {
                setData(data.content);
                setTotalRows(data.totalElements);
                callbackrows(data.content);
            }
            // setLoading(false);
        });
    };


    //强制刷新
    const forceRefresh = (key) => {
        const new_params = {...refresh_params};
        if (!(key in new_params)) {
            new_params[key] = key;
        } else {
            delete new_params[key];
        }
        setRefreshParams(new_params);
    }

    const handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelectedIds(ids);
        } else {
            setSelectedIds([]);
        }
    };

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedIds([row.id, ...selectedIds])
        } else {
            setSelectedIds(selectedIds.filter(x => x !== row.id));
        }
    };

    const selectRow = {
        mode: selection_mode,
        clickToSelect: true,
        selected: selected,
        //可以先调用，再用回调方法传出ids
        onSelect: handleOnSelectOuter ? (row, isSelect) => {
            handleOnSelectOuter(row, isSelect);
            handleOnSelect(row, isSelect);
        } : handleOnSelect,
        onSelectAll: handleOnSelectAllOuter ? (isSelect, rows) => {
            handleOnSelectAllOuter(isSelect, rows);
            handleOnSelectAll(isSelect, rows);
        } : handleOnSelectAll
    }

    //表参数变化事件
    const handleTableChange = (type, {page, sizePerPage}) => {
        setPage(page);
        setPerPage(sizePerPage);
    }

    const handleCreateOrEdit = (edit = false) => {

        const id = selectedIds.length > 0 ? selectedIds[0] : '';

        if (edit === true && !id) {
            window.alert("请选择一条数据！");
            return;
        }
        if (!save_one) {
            if (id) {
                window.location.hash = `${base_link}/edit/${id}`;
            } else {
                window.location.hash = `${base_link}/add`;
            }
        } else {
            save_one(id);
        }
    }

    const handleDelete = () => {
        const ids = [...selectedIds];
        if (!ids || ids.length === 0) {
            window.alert('请选择数据！');
            return;
        }

        var ok = window.confirm('是否确定删除');
        if (!ok) return;


        delete_many(ids.map(id => {
            return {id: id}
        }), data => {
            if (data.ok) {
                window.alert("删除成功！");
                forceRefresh('refresh');
            } else {
                window.alert("删除出错！");
            }
        });
    }

//防止页面状态错误，判断参数是否变化，数据由useEffect统一加载
    useEffect(() => {
        request_datas(page);
    }, [page, perPage, sort, refresh, refresh_params]);


    const options = {
        custom: true,
        pagenum: page,
        sizePerPage: perPage,
        totalSize: totalRows,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true
    }


    // @ts-ignore
    return (
        <Card style={{height: height}}>
            <Card.Header>
                <TableToolBar onCreate={showadd ? handleCreateOrEdit : null}
                              onDelete={showdelete ? handleDelete : null}
                              onEdit={showedit ? handleCreateOrEdit : null}
                              onRefresh={showrefresh ? forceRefresh : null}
                />
                {children}
            </Card.Header>
            <PaginationProvider
                pagination={
                    paginationFactory(options)
                }>
                {
                    ({
                         paginationProps,
                         paginationTableProps
                     }) => (
                        <>
                            <Card.Body style={{...style}}>
                                <BootstrapTable
                                    keyField={id}
                                    bootstrap4

                                    wrapperClasses="table-responsive tableHeight"
                                    // rowClasses="text-nowrap"

                                    striped
                                    hover
                                    condensed

                                    data={data}
                                    columns={columns}

                                    // selected={selected}
                                    selectRow={selectRow}

                                    sort={{
                                        dataField: sort.field,
                                        order: sort.order || 'asc'
                                    }}

                                    defaultSorted={[{
                                        dataField: defaultSort.field,
                                        order: defaultSort.order || 'asc'
                                    }]}

                                    onTableChange={handleTableChange}

                                    //注意，服务器分页必须用remote
                                    remote
                                    {...paginationTableProps}
                                />
                            </Card.Body>
                            {
                                showpage && <Card.Footer>
                                    <Row>
                                        <Col>
                                        <PaginationListStandalone {...paginationProps} />
                                        </Col>
                                    <Col>

                                    <div style={{float:"right"}}>总条数：{totalRows}，每页条数：{perPage}，当前页码：{page}，当前数据：{perPage * (page === 1 ? 0 : page - 1) + 1}-{perPage * page > totalRows ? totalRows : perPage * page}</div>
                                    </Col>
                                    </Row>
                                </Card.Footer>
                            }
                        </>)
                }
            </PaginationProvider>
        </Card>
    );
};

export default ReactDataTable;
