import qs from "qs"
import {get_data, post_data} from "../common/simple_request";

const base_url = `/api/stats`;


export function stats_get_list(params, callback = (data: any) => {
}) {
    return post_data(`${base_url}/deptusers`, qs.stringify(params), callback);
}

export function deptsteps_get_list(params, callback = (data: any) => {
}) {
    return post_data(`${base_url}/deptsteps`, qs.stringify(params), callback);
}

export function usersteps_get_list(params, callback = (data: any) => {
}) {
    return post_data(`${base_url}/usersteps`, qs.stringify(params), callback);
}

export function stats_load_one(id?: string, callback = (data: any) => {
}) {
    return get_data(`${base_url}/${id}`, null, callback);
}

export function stats_save_one(obj: any, callback = (data: any) => {
}) {
    return post_data(`${base_url}/save`, qs.stringify(obj), callback);
}

// 数据过滤
export function stats_finishstats(obj: any, callback = (data: any) => {
}) {

    return post_data(`${base_url}/finishstats`, qs.stringify(obj), callback);
}


// 数据导出

export function stats_export_finishstats(params, callback = (data: any) => {
}) {
    
    return get_data(`${base_url}/export/finishstats`, params, callback);
}