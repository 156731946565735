import axios from "axios";
import qs from "qs"

export const do_login = (username, password, ok_func = (data) => {
}) => {

    axios.request({
        url: "/api/login",
        method: "post",
        /*headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        },*/
        // auth: {
        //     username: CLIENT_ID,
        //     password: CLIENT_SECRET
        // },
        data: qs.stringify({
            "username": username,
            "password": password
        })
    }).then(respose => {
        ok_func(respose.data);
    }).catch(reason => {
        ok_func({ok: false, msg: "请求出错：" + reason});
    });
}
