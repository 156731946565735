import {useState} from "react";
import {stats_finishstats} from "../../api/stats";
import {get_orgId, get_orgname} from "../../common/auth_utils";
import {Button, Col, Form, Row} from "react-bootstrap";
import DatePicker, {registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import {export_format_date, user_format_date} from "../../common/date_utils";
import BootstrapTable from 'react-bootstrap-table-next';
import zh from 'date-fns/locale/zh-CN'

registerLocale('zh',zh)

const QueryDownload = (props) => {
    const [data, setData] = useState([])

    const [state, setState] = useState({
        data: {
            daygoalstep: '3000',
            goalratio: '60',
            // deptorder:false
        }
    });

    const [deptorder,setDeptorder] =useState(false)

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(),new Date().getMonth(),1))
    const [endDate, setEndDate] = useState(new Date())
    const begymd = user_format_date(startDate)
    const endymd = user_format_date(endDate)

    const current_time = export_format_date(new Date())


    const filter_page_data = (params, datafunc = (res => {
        if (res.data) {
            var datalist = res.data
            for (var i=0;i<res.data.length;i++){
              datalist[i]['ordernum'] = i+1
            //   console.log(datalist[i])
            //   console.log(datalist)
            // setData(res.data)
            }
            setData(datalist)

        }
    })) => {

        // let new_params = {...params, 'orgId':get_orgId(),'begymd':begymd,'endymd':endymd,'daygoalstep':state.data.daygoalstep,'goalratio':state.data.goalratio};
        stats_finishstats(params, datafunc);
    };


    const columns = [
        {
            text: "序号",
            dataField: "ordernum",
            headerStyle: { width: "10%" },
        },
        {
            text: "部门名称",
            dataField: "deptname",
            headerStyle: { width: "20%" },
            // formatter:(val,row) => {
            //     return (<Form.Switch readOnly checked={!!val}/>)
            // }
        },
        {
            text: "工号",
            dataField: "employeeno",
            headerStyle: { width: "15%" },
        },
        {
            text: "姓名",
            dataField: "employeename",
            headerStyle: { width: "15%" },
        },
        {
            text: "电话",
            dataField: "tel",
            headerStyle: { width: "25%" },
        },
        {
            text: "平均步数",
            dataField: "avgstepcount",
            headerStyle: { width: "15%" },
            formatter: (val, row) => {
                var format = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                return <span style={{float:'right'}}>{format}</span>
            }
        },
        {
            text: "总步数",
            dataField: "totalstepcount",
            headerStyle: { width: "15%",},
            formatter: (val, row) => {
                var format = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                return <span style={{float:'right'}}>{format}</span>
            }
        },
        {
            text: "达标天数",
            dataField: "finishdays",
            headerStyle: { width: "10%" },
        },
        {
            text: "达标率%",
            dataField: "finishratio",
            headerStyle: { width: "15%" },
            formatter: (val, row) => {
                return val.toFixed(2)

            }
        },
    ];


    function handleChange({ name, value }) {
        const data = { ...state.data };
        data[name] = value;

        setState({ ...state, 'data': data });
    }

    // const handleExcelDownload=(params,func)=>{

    // }


    const handleFileDownload = (url, filename) => {
        // 创建 a 标签
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
    };

    const handleExcelDownload = (url, filename) => {
        fetch(url, {
            method: 'get',
            // credentials:'same-origin'
            // responseType: 'arraybuffer',
        }).then(function (res) {
            if (res.status !== 200) {
                return res.json()
            }
            return res.arrayBuffer()
        }).then((blobRes) => {
            // 生成 Blob 对象，设置 type 等信息
            const obj = new Blob([blobRes], {
                type: 'application/octet-stream',
                // 'Content-Disposition': 'attachment'
            });
            // 将 Blob 对象转为 url
            const link = window.URL.createObjectURL(obj);
            handleFileDownload(link, filename)
        })
    };





    const handlefliter = () => {
        return (
            <div style={{height: 'calc(72vh)'}}>
                <Form style={{ margin: '5px' }} onSubmit={(e) => {
                    e.preventDefault();
                    filter_page_data({ 'orgId': get_orgId(), 'begymd': begymd, 'endymd': endymd, 'daygoalstep': state.data.daygoalstep, 'goalratio': state.data.goalratio,'sortbydept':deptorder },)
                }}>
                    <Row>
                        <Col md='5'>
                            <span>日步数标准</span>
                            <Form.Group className="mb-3" controlId="text">

                                <input type="text" name='daygoalstep' placeholder="日步数标准" value={state.data.daygoalstep} onChange={e => handleChange(e.target)} />
                            </Form.Group>
                        </Col>

                        <Col md='3'>
                            <span>达标率%</span>
                            <Form.Group className="mb-3" controlId="text">

                                <input type="text" name='goalratio' placeholder="达标率%" value={state.data.goalratio} onChange={e => handleChange(e.target)} />
                            </Form.Group>
                        </Col>
                        <Col>

                        <Form.Switch
                            style={{ marginTop: '24px' }}
                            // type="switch"
                            name='deptorder'
                            id="custom-switch"
                            label="部门排序"
                            onChange={e=>{
                                let value = e.target.checked
                                setDeptorder(value)
                                // e.preventDefault();
                                // filter_page_data({ 'orgId': get_orgId(), 'begymd': begymd, 'endymd': endymd, 'daygoalstep': state.data.daygoalstep, 'goalratio': state.data.goalratio,'sortbydept':deptorder },)
                            }}
                        />
                        </Col>
                    </Row>


                    <Row>
                        <Col md='5'>
                            <span>
                                开始日期<DatePicker
                                    as={DatePicker}
                                    locale={'zh'}
                                    dateFormat='yyyy-MM-dd'
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                     />

                            </span>
                        </Col>
                        <Col md='3'>
                            <span>
                                结束日期<DatePicker
                                    as={DatePicker}
                                    locale={'zh'}
                                    dateFormat='yyyy-MM-dd'
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)} />
                            </span>
                        </Col>
                        <Col md='2' style={{ marginTop: '24px' }}>
                            <Button size='sm' variant="primary" type="submit">
                                搜索
                            </Button>
                        </Col>

                        <Col md='2' style={{ marginTop: '24px' }}>
                            <Button size='sm' variant="success" onClick={()=>{handleExcelDownload(`/api/stats/export/finishstats?orgId=${get_orgId()}&begymd=${begymd}&endymd=${endymd}&daygoalstep=${state.data.daygoalstep}&goalratio=${state.data.goalratio}&sortbydept=${deptorder}`,`数据统计${current_time}.xlsx`)}}>

                                下载Excel文件
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <BootstrapTable
                    keyField='ordernum'
                    // height={'100%'}
                    style={{height: 'calc(75vh - 20px)'}}
                    columns={columns}
                    data={data}
                ></BootstrapTable>
            </div>
        )
    }




    return (
        <>
            <h4>
                {get_orgname()}
            </h4>

            {handlefliter()}

        </>
    );
}
export default QueryDownload
