import moment from "moment";

export const to_short_date = (str_date, format = "YYYY-MM-DD") => {
    return str_date ? moment(str_date, format).toDate() : null;
}

export const format_date = (date, format = "YYYY-MM-DD") => {
    return date ? moment(date).format(format) : null;
}

export const user_format_date = (date, format = "YYYYMMDD") => {
    return date ? moment(date).format(format) : null;
}

export const export_format_date = (date, format = "YYYYMMDDhm") => {
    return date ? moment(date).format(format) : null;
}