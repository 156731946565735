import qs from "qs"
import {delete_data, get_data, post_data} from "../common/simple_request";
import {get_orgId} from "../common/auth_utils";

const base_url = `/api/qrcode`;



export function qrcode_get_list(params, callback = (data: any) => {
}) {
    return get_data(`${base_url}/bycondition`, params, callback);
}

export function qrcode_delete_many(objs: [], callback = (data: any) => {
}) {
    return delete_data(`${base_url}/delmany`, objs, callback);
}

export function qrcode_load_one(id?: string, callback = (data: any) => {
}) {
    return get_data(`${base_url}/${id}`, null, callback);
}

export function qrcode_save_one(obj: any, callback = (data: any) => {
}) {
    return post_data(`${base_url}/save`, qs.stringify(obj), callback);
}

// 获取签到二维码
export function qrcode_get_sign( callback = (data: any) => {
}) {
    let params={'orgId':get_orgId()}
    return post_data(`${base_url}/gencheckinqrcode`, qs.stringify(params), callback);
}

export function randcode_get( callback = (data: any) => {
}) {
    let params={'orgId':get_orgId()}
    return post_data(`${base_url}/createrandcode`, qs.stringify(params), callback);
}