// 引入axios
import axios from 'axios'
import {get_access_token} from "./auth_utils";

//设置默认的提交格式
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 创建axios实例g
const request = axios.create({
    timeout: 5000, // 请求超时时间
    withCredentials: true, // 跨域携带cookie
    xsrfCookieName: 'xsrf-token'  //当创建实例的时候配置默认配置
});


// 添加请求拦截器，这里面可以配置一下每次请求都需要携带的参数，比如 token，timestamp等等，根据项目自己配置
request.interceptors.request.use(
    function (config) {
        const token = get_access_token();
        if(token) {
            // config.headers['Authorization'] = "Bearer " + token;
            config.headers['Authorization'] = "JWT " + token;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
)

// 添加响应拦截器 ，这里的 response是接收服务器返回后的结果，也可以在这里做一些状态判断
request.interceptors.response.use(
    response => {
        if (response.status !== 200) {
            return Promise.reject(new Error('网络异常，请稍后重试'))
        }
        const res = response.data;
        return res;
    },
    error => {
        return Promise.reject(error);
    }
)

export default request;
